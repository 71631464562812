import "./Header.css";
import headering from "../header-img.svg";

export const Header = () => {
  return (
    <div className="header">
      <div className="image">
        <img src={headering} alt="Bilder på hus" />
      </div>
      <div className="content">
        <h1>MinElräkning.se</h1>
        <p>Har du också fått en hög elräkning? Moderaterna föreslår slopad* energiskatt i januari och februari. Räkna ut hur mycket lägre elräkning du skulle få med förslaget!</p>
          <small>*Skatten sänks från 36 öre/kWh exklusive moms (45 öre inklusive moms), till EU:s miniminivå, 1 öre/kWh.)</small>
      </div>
    </div>
  );
}
