export interface TaxResult {
  tax: number;
  annualProperty: number;
  altitudeTax: number;
  amountOdTax: number;
}

export const calculateTax = (marketValue: number) => {
  const tax = (marketValue * 0.35) * 1.25;

  return {
    tax: Math.round(tax),
  }
}
