import React from "react";
import "./Subscribe.css";

export class Subscribe extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      values: {
        name: "",
        email: ""
      },
      isSubmitting: false,
      isError: false,
      isSuccess: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleChange(e: any) {
    this.setState({
      values: {
        ...this.state.values,
        [e.target.name]: e.target.value
      }
    });
  }

  submitForm(e: any) {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    fetch("/collect.php", {
      method: "POST",
      body: JSON.stringify(this.state.values),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      this.setState({ isSubmitting: false });
      return res.json();
    }).then(data => {
      if (data.hasOwnProperty("error")) {
        this.setState({ isError: true, isSuccess: false });
      } else {
        this.setState({ isError: false, isSuccess: true });
      }
    }).catch(err => {
      this.setState({ isSubmitting: false, isError: true, isSuccess: false });
    })
  }

  render() {
    const isSubmitting = this.state.isSubmitting;
    const isValid = this.state.values.name && this.state.values.email;
    let error;
    let success;

    if (this.state.isError) {
      error = <p className="error">Det gick inte att skicka in dina uppgifter. Vänligen försök igen senare.</p>;
    }
    if (this.state.isSuccess) {
      success = <p className="success">Tack för din medverkan!</p>
    }

    return (
      <form className="subscribe" onSubmit={this.submitForm}>
        <div className="subrow">
          <span>Skriv under här om du också vill slopa energiskatten!</span>
        </div>
        <div className="subrow">
          <input type="text"
                 name="name"
                 className="textinput"
                 placeholder="Namn"
                 onChange={this.handleChange}
                 value={this.state.values.name} />
          <input type="email"
                 name="email"
                 className="textinput"
                 placeholder="E-post"
                 onChange={this.handleChange}
                 value={this.state.values.email} />
          <button type="submit"
                  className="submitbtn"
                  disabled={!isValid || isSubmitting}>Skriv under</button>
        </div>
        <div>
          {error}
          {success}
        </div>
        <div className="subrow">
          <span>
            <small>
              Efter att du skickar in ditt namn och e-postadress kommer vi att behandla dina personuppgifter i enlighet med vår <a href="https://moderaterna.se/gdpr" target="_blank" rel="noreferrer">integritetspolicy</a> för att skicka vårt nyhetsbrev om energiskatten och lämna information i övrigt till dig. Du kan närsomhelst begära att sluta få vårt nyhetsbrev.
            </small>
          </span>
        </div>
      </form>
    );
  }
}
