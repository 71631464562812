import React from "react";
import "./AboutCampaign.css";

export class AboutCampaign extends React.Component<any, any> {
  render() {
    return (
      <div className="about">
        <h2>Om kampanjen</h2>
        <p>En huvudlös energipolitik, där S och MP-regeringen stängt ned viktig kärnkraft i förtid, resulterar nu i skyhöga elpriser över stora delar av landet. Vi vill få ordning på de skenande elpriserna för svenska hushåll och företag. På kort sikt föreslår vi slopad skatt på el i januari och februari 2022. På längre sikt vill vi säkra stabil och planerbar energiproduktion genom tex nästa generations kärnkraft (SMR).</p>
        <p>Hälften av elräkningen består redan av skatt och det är orimligt att staten ska tjäna ännu mer när elpriserna skenar. Består de höga elpriserna kommer staten att tjäna närmare 7 miljarder kronor bara i ökad moms. Vi tycker att skatten ska gå tillbaka till användarna och föreslår därför slopad energiskatt till EU:s miniminivå. Läs mer om förslaget <a href="https://moderaterna.se/moderaterna-vill-tillfalligt-slopa-elskatten-staten-ska-inte-tjana-pengar-pa-rekordhoga-elpriser" target="_blank">här.</a></p>
      </div>
    );
  }
}
