import React from "react";
import "./Result.css";
import { Subscribe } from "./Subscribe";

export class Result extends React.Component<any, any> {
  render() {
    const tax = this.formatString(this.props.result.tax);
    let output;

    switch(this.props.selectedType) {
      case "home": {
        output = (<div className="result-row" style={{marginTop:"48px"}}>
          <span className="result-key">*Gäller januari och februari 2021</span>
        </div>);
        break;
      }
      default: {
        output = (<div className="result-row" style={{marginTop:"48px"}}></div>);
      }
    }

    return (
      <div className="result">
        <h2>Så mycket sparar du per månad med Moderaternas förslag</h2>
        <div className="result-row">
          <div className="result-value">{tax} kr*</div>
        </div>
         {output}
        <Subscribe/>

      </div>
    );
  }

  private formatString(input: number | string): string {
    return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
}
