import { Link } from "react-router-dom";
import "./Footer.css";
import logo from "../logo.svg";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="menu">
        <ul>
          <li><Link to="/">Start</Link></li>
          <li><Link to="/om-kampanjen">Om kampanjen</Link></li>
        </ul>
      </div>
      <img src={logo} alt="Moderaterna" />
    </div>
  );
}
