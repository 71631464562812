import React from "react";
import "./Form.css";

export interface InputFormState {
  selectedType: string;
  kwh: number | string;
}

export class InputForm extends React.Component<any, InputFormState> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedType: "home",
      kwh: ""
    };
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  onChangeType(event: any) {
    this.setState({
      selectedType: event.target.value
    });
  }

  onChangeValue(event: any) {
    this.setState({
      kwh: event.target.value
    });
  }

  formSubmit(event: any) {
    event.preventDefault();
    this.props.onSubmit(this.state);
  }

  render() {
    const isValid = parseInt(this.state.kwh.toString()) > 0;

    return (
      <form onSubmit={this.formSubmit}>
        <div className="row">
          <span className="inputlabel">Fyll i antal kWh ditt hushåll använt enligt senaste månadsfakturan från din elleverantör</span>
          <input type="number"
                 name="value"
                 placeholder=""
                 className="numberinput"
                 value={this.state.kwh?.toString()}
                 onChange={this.onChangeValue} />
          <button className="button" disabled={!isValid}>Räkna ut min besparing</button>
        </div>
      </form>
    );
  }
}
